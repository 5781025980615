import styled, { css } from 'styled-components/macro'
import { COLORS } from '~theme/colors'

import { StyledLink } from '~components/atoms/link'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { StyledParagraph } from '~components/atoms/typography/paragraph/paragraph.styles'
import {
  StyledAccordionItemBody,
  StyledAccordionItemHeader,
} from '~components/molecules/accordion/accordion.styles'

import { Colors, Fonts } from '~theme/deprecated-vt/deprecated-vt-theme'

const TABLE_BG_COLOR = Colors.white

export const StyledTableCell = styled.td<{
  backgroundColor?: string
  hasComponent?: boolean
  isSubHeading?: boolean
  textTransform?: 'uppercase' | 'none'
}>`
  vertical-align: middle;
  padding: 10px 16px;
  background: ${(p) => p.backgroundColor ?? 'transparent'};
  text-transform: ${(p) => p.textTransform ?? 'none'};
  border-top: ${(p) => (p.isSubHeading ? 12 : 6)}px solid ${TABLE_BG_COLOR};

  @media only screen and (min-width: 1500px) {
    padding: 14px 16px;
  }

  @media only screen and (min-width: 2000px) {
    padding: 18px 16px;
  }

  &:first-child {
    padding-left: 32px;
    text-align: left;
  }

  &:last-child {
    padding-right: 42px;
  }

  ${StyledParagraph} {
    line-height: 1.3;

    ${(p) =>
      p.hasComponent
        ? css`
            display: inline-block;
            padding-right: 10px;
          `
        : css`
            overflow: hidden;
            text-overflow: ellipsis;
          `}
  }

  ${StyledLink} {
    ${StyledParagraph} {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const StyledTable = styled.table<{ useAccordion?: boolean }>`
  color: ${COLORS.secondary.darkBlue};
  position: relative;
  width: 100%;
  background: ${TABLE_BG_COLOR};
  table-layout: fixed;

  ${StyledAccordionItemBody},
  ${StyledAccordionItemHeader} {
    table {
      margin: 0;
    }
  }

  ${StyledAccordionItemBody} {
    > div {
      padding-bottom: 6px;
    }

    ${StyledAccordionItemBody} {
      td {
        border: none;
      }
    }
  }
`

export const StyledTableRow = styled.tr``

export const StyledTableHead = styled.thead`
  ${StyledTableCell} {
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`

export const StyledTableBody = styled.tbody``

export const StyledTableCellIcon = styled.span<{
  color?: string
  hasBorder?: boolean
  svgProps?: any
}>`
  display: inline-block;
  vertical-align: center;

  svg {
    display: block;
    border: ${(p) => (p.hasBorder ? `1px solid ${p.color}` : 'none')};
    fill: ${(p) => p.color ?? 'inherit'};
    border-radius: ${(p) => (p.hasBorder ? '50%' : 'none')};

    ${(p) => p.svgProps}
  }
`

export const StyledTableAccordionItemHeaderInner = styled.div<{
  isOpen?: boolean
}>`
  > span {
    position: absolute;
    right: 16px;
    top: calc(50% + 4px);
    transform: translateY(-50%) rotate(${(p) => (p.isOpen ? -90 : 0)}deg);
    transition: 0.2s;
  }
`

export const StyledTableCellContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 20px;
`

export const StyledTableStatus = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  text-align: center;

  p {
    margin-bottom: 20px;
  }
`

export const StyledTableFooter = styled(Paragraph)`
  font-size: ${Fonts.sizes.caption};
  font-family: ${Fonts.families.bold};
  color: ${COLORS.primary.maerskBlue};
`
