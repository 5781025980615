import { SWRResponse } from 'swr'
import { COLORS } from '~theme/colors'

import { FALLBACK_STRING_DASH } from 'src/constants/global-strings'
import { IError } from '~api/types'
import { Tooltip } from '~components/tooltip'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { FormState } from '~components/organisms/form/form-provider'
import { MainTableWrapper } from '~components/organisms/table/main-table-wrapper/main-table-wrapper'
import {
  DataTransformerConfig,
  TableDataTransformerTable,
} from '~components/organisms/table/table-data-transformer/table-data-transformer.types'
import { StyledTableCellIcon } from '~components/organisms/table/table.styles'
import { TableRowConfig } from '~components/organisms/table/table.types'
import { TabTooltipDynamicContent } from '~components/organisms/tooltips/tab-tooltip-dynamic-content'
import { BaseStoreState } from '~context/store-provider'
import { PoolPointsPageState } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-page-provider'
import { getHighlightedDatepickerDates } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-page.utils'
import { PoolPointsResult } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-result/pool-points-result'
import { PoolPointsTable } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-table/pool-points-table'
import { getPoolPointsTableGroupedRowData } from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-table/pool-points-table.utils'
import { VesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import {
  PoolPoints,
  PoolPointsCorrectionInput,
  PoolPointsFactor,
} from '~types/pool-points.types'
import { toDateFormat } from '~utils/dates'

export type TransformedPoolPointsTableData = {
  fields: PoolPoints
  id: string
  name: string
}

export const PP_DATEPICKER_ID = 'datepicker-poolpoints-year'
export const PP_BASE_POINTS = 100

const getPpResultFactorColspan = (isMtUser: boolean) => (isMtUser ? 4 : 3)
const getPpResultPpColSpan = (isMtUser: boolean) => (isMtUser ? 3 : 2)

const getTooltipConfig = (pool: string | undefined) => ({
  bwDraft: (
    // We're using li's for formatting purposes
    // The tooltip isn't made for multiple line text
    <ul>
      {[
        'Ability to lift 37,000 mts',
        '(with 1,500 mts bunkers/constants)',
        'on or below 37 ft. (11.278m) BW draft',
      ].map((s) => (
        <li key={s}>
          <Paragraph size="xSmall">{s}</Paragraph>
        </li>
      ))}
    </ul>
  ),
  capNo: (
    <ul>
      {['Only applicable if', 'vessel age > 15 years'].map((s) => (
        <li key={s}>
          <Paragraph size="xSmall">{s}</Paragraph>
        </li>
      ))}
    </ul>
  ),
  ...(pool === 'City' && {
    cubic: (
      <Paragraph size="xSmall">
        Pool points for cubic to deadweight ratio
      </Paragraph>
    ),
  }),
  ...((pool === 'City' || pool === 'Intermediate') && {
    tankCoating: (
      <ul>
        {(pool === 'City'
          ? [
              'High performance polymer-based cargo tank coatings such as',
              'Marineline, Jotun Flexline, interline 9001 or similar',
            ]
          : ['Advanced Polymer (AP), Stainless Steel (SS) or any other coating']
        ).map((s) => (
          <li key={s}>
            <Paragraph size="xSmall">{s}</Paragraph>
          </li>
        ))}
      </ul>
    ),
  }),
  ...(pool === 'Intermediate' && {
    suezCanalTrading: (
      <Paragraph size="xSmall">
        Trading East and West of Suez Canal without any restrictions
      </Paragraph>
    ),
  }),
  ...(pool === 'LR1' && {
    mooringDrums: <Paragraph size="xSmall">16 or more mooring drums</Paragraph>,
  }),
  ...(pool === 'Handy' && {
    swDraft: (
      <ul>
        {[
          'Draft in salt water with a load of',
          '33,000 mt cargo and 1,500 mt constants',
          '(including slops, bunkers, freshwater and stores)',
        ].map((s) => (
          <li key={s}>
            <Paragraph size="xSmall">{s}</Paragraph>
          </li>
        ))}
      </ul>
    ),
  }),
})

const getUserData = (
  _: any,
  __: PoolPointsFactor[],
  tableRowItem: PoolPointsFactor,
) => tableRowItem.user?.name || tableRowItem.user?.email || FALLBACK_STRING_DASH

export const getPoolPointsTableConfig = (
  store: BaseStoreState,
  state: PoolPointsPageState,
) => ({
  rowConfig: {
    dataSourceConfig: {
      dataFn: (tableData: TransformedPoolPointsTableData) =>
        getPoolPointsTableGroupedRowData(tableData),
    },
    cellConfig: getPoolPointsTableCellConfig(store, state),
  },
})

type TransformedPoolPointsFactor = PoolPointsFactor & { key: string }

export const getPoolPointsTableCellConfig = (
  store: BaseStoreState,
  state: PoolPointsPageState,
) => {
  const isMtUser = store.User.isMTUser()
  const pool = state.vessel?.pool

  return [
    {
      heading: '',
      componentFn: (
        tableData: any,
        tableRowList: PoolPointsFactor[],
        tableRowItem: TransformedPoolPointsFactor,
      ) => {
        const tooltipConfig =
          getTooltipConfig(pool)[
            tableRowItem.key as keyof ReturnType<typeof getTooltipConfig>
          ]

        if (!tooltipConfig) return

        return (
          <StyledTableCellIcon>
            <Tooltip
              title={
                <TabTooltipDynamicContent>
                  {tooltipConfig}
                </TabTooltipDynamicContent>
              }
            />
          </StyledTableCellIcon>
        )
      },
    },
    {
      heading: 'Factor',
      fontWeight: 'bold',
      colSpan: 2,
      textTransform: 'uppercase',
      customCellValueFn: (
        _: any,
        __: PoolPointsFactor[],
        tableRowItem: TransformedPoolPointsFactor,
      ) => {
        const factorVal = tableRowItem.label
        const measure = tableRowItem.measure

        if (!measure) return factorVal

        return `${factorVal} (${measure})`
      },
    },
    {
      heading: 'Parameter',
      key: 'input',
      expandedCellConfig: {
        key: 'value',
      },
    },
    {
      heading: 'Pool Points Range',
      componentFn: (
        tableData: any,
        tableRowList: PoolPointsFactor[],
        tableRowItem: TransformedPoolPointsFactor,
        tableRowItemIndex: number,
      ) => {
        const resultsRowIndex = Object.keys(tableData.fields).findIndex(
          (key) => key === 'results',
        )

        if (resultsRowIndex === tableRowItemIndex) {
          return (
            <Paragraph color={COLORS.secondary.midBlue} size="medium">
              <strong>{tableRowItem.range?.min ?? 0}</strong> of{' '}
              {tableRowItem.range?.max ?? 0} possible points
            </Paragraph>
          )
        }

        return null
      },
      customCellValueFn: (
        tableData: any,
        tableRowList: PoolPointsFactor[],
        tableRowItem: TransformedPoolPointsFactor,
        tableRowItemIndex: number,
      ) => {
        const resultsRowIndex = Object.keys(tableData.fields).findIndex(
          (key) => key === 'results',
        )

        if (resultsRowIndex === tableRowItemIndex) {
          return null
        }

        return `${tableRowItem.range?.min ?? 0} - ${
          tableRowItem.range?.max ?? 0
        }`
      },
    },
    {
      heading: 'Pool Points Assigned',
      fontWeight: 'bold',
      customCellValueFn: (
        _: any,
        __: PoolPointsFactor[],
        tableRowItem: TransformedPoolPointsFactor,
      ) =>
        typeof tableRowItem.result === 'string'
          ? FALLBACK_STRING_DASH
          : tableRowItem.result || '0',
    },
    ...(isMtUser
      ? [
          {
            heading: 'Source',
            customCellValueFn: getUserData,
            expandedCellConfig: {
              customCellValueFn: getUserData,
            },
          },
          {
            heading: 'Version History',
            key: 'updatedAt',
            isDateValue: true,
            expandedCellConfig: {
              key: 'updatedAt',
              isDateValue: true,
            },
          },
        ]
      : []),
  ].map((tableCellConfig) => ({
    ...tableCellConfig,
    backgroundColor: COLORS.greys.ultraLight,
    expandedCellConfig: {
      ...(tableCellConfig.expandedCellConfig ?? {}),
      backgroundColor: COLORS.greys.light,
      colSpan: tableCellConfig.colSpan,
    },
  }))
}

export function getCustomPoolPointsTableResultsRow(
  store: BaseStoreState,
  tableDataConfig: TableDataTransformerTable,
) {
  const isMtUser = store.User.isMTUser()
  const resultsRow = tableDataConfig.rows?.[tableDataConfig.rows.length - 1]

  // Manually set config for the last (results) row
  return {
    ...resultsRow,
    cells:
      resultsRow?.cells
        ?.map((cellConfig, index) => {
          if (index === 0) {
            return cellConfig
          }

          if (index === 1) {
            return {
              ...cellConfig,
              colSpan: getPpResultFactorColspan(isMtUser),
            }
          }

          if (index === 3) {
            return { ...cellConfig, colSpan: getPpResultPpColSpan(isMtUser) }
          }

          return null
        })
        .filter(Boolean)
        .map((cellConfig) => ({
          ...cellConfig,
          backgroundColor: COLORS.secondary.blueTint,
          textColor: COLORS.secondary.midBlue,
          fontSize: 'medium',
          textTransform: 'none' as const,
        })) ?? [],
  }
}

export function valToDecimals(val: number): number {
  // Math.round handles the amount of decimals
  return Math.round(val * 1e2) / 1e2
}

export const getCustomPoolPointsResultsTableData = (
  store: BaseStoreState,
  state: PoolPointsPageState,
  vesselPageStage: VesselPageState,
  formState: FormState,
  poolPointsApiData: PoolPoints,
): TableRowConfig[] => {
  const isMtUser = store.User.isMTUser()
  const correctionRows =
    poolPointsApiData.poolPointsCorrections.input
      ?.flatMap((data: PoolPointsCorrectionInput) =>
        getCustomPoolPointsCorrectionsTableData(store, state, formState, data),
      )
      .filter(Boolean) || []
  const totalPointPoints = poolPointsApiData.totalPoolPoints?.result
    ? poolPointsApiData.totalPoolPoints?.result
    : 0
  const poolPointsPlusCorrections = poolPointsApiData
    .totalPoolPointsPlusCorrections?.result
    ? poolPointsApiData.totalPoolPointsPlusCorrections?.result
    : 0

  return [
    ...[
      {
        cells: [
          { data: '' },
          {
            data: 'Base pool points',
            colSpan: getPpResultFactorColspan(isMtUser),
          },
          {
            data: PP_BASE_POINTS,
            colSpan: getPpResultPpColSpan(isMtUser),
          },
        ],
      },
      {
        cells: [
          { data: '' },
          {
            data: 'Total pool points',
            colSpan: getPpResultFactorColspan(isMtUser),
          },
          {
            data: valToDecimals(totalPointPoints - PP_BASE_POINTS),
            colSpan: getPpResultPpColSpan(isMtUser),
          },
        ],
      },
    ].map(({ cells }) => ({
      ...cells,
      cells: cells.map((cell) => ({
        ...cell,
        backgroundColor: COLORS.greys.ultraLight,
        fontWeight: 'bold',
        textTransform: 'uppercase',
      })),
    })),
    ...(correctionRows || []),
    correctionRows.length > 0
      ? {
          cells: [
            { data: '' },
            {
              data: 'Assigned points from corrections',
              colSpan: getPpResultFactorColspan(isMtUser),
            },
            {
              data: valToDecimals(poolPointsPlusCorrections - totalPointPoints),
              colSpan: getPpResultPpColSpan(isMtUser),
            },
          ].map((cell) => ({
            ...cell,
            backgroundColor: COLORS.greys.ultraLight,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          })),
        }
      : {},
    {
      cells: [
        { data: '' },
        {
          data: `Pool Points for ${
            vesselPageStage.vessel?.vesselName || 'Unknown'
          }`,
          colSpan: getPpResultFactorColspan(isMtUser),
        },
        {
          component: (
            <PoolPointsResult
              color={COLORS.secondary.midBlue}
              resultFontSize="medium"
              showPointsOnly
            />
          ),
          colSpan: getPpResultPpColSpan(isMtUser),
        },
      ].map((cell) => ({
        ...cell,
        backgroundColor: COLORS.secondary.blueTint,
        textColor: COLORS.secondary.midBlue,
        fontWeight: 'bold',
        fontSize: 'medium',
      })),
    },
  ] as TableRowConfig[]
}

export const getCustomPoolPointsCorrectionsTableData = (
  store: BaseStoreState,
  state: PoolPointsPageState,
  formState: FormState,
  correctionData: PoolPointsCorrectionInput,
) => {
  if (!correctionData) return

  const validToVal =
    new Date(correctionData.validTo).getFullYear() >= 9999
      ? 'Unlimited'
      : toDateFormat(correctionData.validTo)

  return [
    {
      cells: [
        { data: '' },
        {
          data: 'Correction',
          fontWeight: 'bold',
        },
        {
          data: correctionData.reason,
          colSpan: 3,
        },

        {
          data: correctionData.poolPoints,
          fontWeight: 'bold',
        },
        {
          colSpan: 2,
          data: `${toDateFormat(correctionData.validFrom)} - ${validToVal}`,
        },
      ].map((cell) => ({
        ...cell,
        textColor: COLORS.red.tint1,
        backgroundColor: COLORS.red.tint3,
      })),
    },
  ]
}

export const poolPointsTableComponentConfig: DataTransformerConfig<
  PoolPoints[]
> = {
  wrapperConfig: {
    // @ts-ignore
    Component: MainTableWrapper,
    filterConfig: {
      datepickerConfig: [
        {
          label: 'Date',
          name: PP_DATEPICKER_ID,
          highlightDatesFn: (
            _: SWRResponse<PoolPoints[], IError>,
            state: PoolPointsPageState,
          ) => getHighlightedDatepickerDates(state.versions),
        },
      ],
    },
  },
  tableConfig: {
    Component: PoolPointsTable,
    dataSourceConfig: {
      dataFn: (tableData: SWRResponse<PoolPoints[], IError>) => {
        if (!tableData.data || tableData.data.length === 0) return []

        const data = tableData.data[0]

        const groups = [
          ...new Set(
            Object.keys(data)
              // @ts-ignore
              .map((key) => data?.[key]?.group)
              .filter(Boolean),
          ),
        ]

        return groups.map((groupStr) => {
          const tableId = `${groupStr
            .split(' ')
            .map((str: string, index: number) =>
              index === 0
                ? str.toLowerCase()
                : str.charAt(0).toUpperCase() + str.slice(1),
            )
            .join('')}PoolPoints`

          const staticHiddenFields = ['summerDraft']

          return {
            name: groupStr,
            id: tableId,
            fields: {
              ...Object.keys(data)
                .filter((key) => !staticHiddenFields.includes(key))
                .reduce((prevVal: PoolPoints, key) => {
                  // @ts-ignore
                  const group = data?.[key]?.group

                  if (group === groupStr) {
                    // @ts-ignore
                    prevVal[key] = data[key]
                  }

                  return prevVal
                }, {} as PoolPoints),
              results: data[tableId as keyof PoolPoints],
            },
          }
        })
      },
    },
    // @ts-ignore
    tableCellConfig: getPoolPointsTableConfig,
  },
}
